import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { useDebouncedEffect } from '@/utils/useDebouncedEffect'
import FroalaWrapper from '@/shared/FroalaWrapper'
import { SidebarAd, MobileAd, SidebarEducationAd, MobileEducationAd, MobileVideomancyAd, SidebarVideomancyAd } from '@/Layout/Ads'
import useQuery from '@/hooks/useQuery'
import useDropdown from '@/hooks/useDropdown'

import Pagination from '@/shared/Pagination'
import Tabs from '@/shared/Tabs'
import StatusPill from '@/shared/StatusPill'
import Loading from '@/shared/Loading'
import { HeartIcon, ArchiveIcon, TrashIcon, PencilIcon, ArrowCircleUpIcon, FilmIcon, ViewGridIcon, ViewListIcon, SortAscendingIcon } from '@heroicons/react/solid'
import Card from '@/shared/Card'

const NoResults = () => {
  return (
    <span>
      <div className="relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccblue" >
        <HeartIcon className="mx-auto h-12 w-12 text-gray-400" />
        <span className="mt-2 block text-sm font-medium text-gray-900 dark:text-gray-300"> You have not favorited anything. Go heart stuff!</span>
      </div>
    </span>
  )
}

const FavoriteRow = ({ favorite, viewPreference }) => {
  const { putpostRequest } = useQuery()
  const { project } = favorite
  const { register, unregister, handleSubmit, setValue, getValues, setError } = useForm({
   defaultValues: {
      notes: favorite?.notes || ''
    }
  })

  const [, setLoading] = useState(false)
  const [deleted, setDeleted] = useState(false)
  const [archived, setArchived] = useState(favorite.status === 'archived')
  const [editing, setEditing] = useState(false)

  useEffect(() => {
    register('notes', { required: true })
    return () => {
      unregister('notes')
    }
  }, [register])

  const handleUpdateFroala = (key, val) => {
    setValue(key, val, { shouldDirty: true, shouldValidate: true })
  }

  const remove = () => {
    const data = { status: 'deleted' }
    putpostRequest(`/api/v3/favorites/${favorite.id}`, 'PATCH', { favorite: data }, (err, jsonData) => {
      setLoading(false)
      if (err) { // 422 code
        Object.entries(err).forEach(([key, value]) => {
          setError(key, value)
        })
        return
      }
      setDeleted(true)
    })
  }
  const unarchive = () => {
    const data = { status: 'active' }
    putpostRequest(`/api/v3/favorites/${favorite.id}`, 'PATCH', { favorite: data }, (err, jsonData) => {
      setLoading(false)
      if (err) { // 422 code
        Object.entries(err).forEach(([key, value]) => {
          setError(key, value)
        })
        return
      }
      setArchived(false)
    })
  }
  const archive = () => {
    const data = { status: 'archived' }
    putpostRequest(`/api/v3/favorites/${favorite.id}`, 'PATCH', { favorite: data }, (err, jsonData) => {
      setLoading(false)
      if (err) { // 422 code
        Object.entries(err).forEach(([key, value]) => {
          setError(key, value)
        })
        return
      }
      setArchived(true)
    })
  }

  const onSubmit = (data) => {
    console.log('hi')
    setLoading(true)

    const action = 'PATCH'
    const url = `/api/v3/favorites/${favorite.id}`
    const formData = { notes: data.notes }

    putpostRequest(url, action, { favorite: formData }, (err, jsonData) => {
      setLoading(false)
      if (err) { // 422 code
        Object.entries(err).forEach(([key, value]) => {
          setError(key, value)
        })
        return
      }
      setEditing(false)
    })
  }

  const notes = getValues().notes

  if (deleted) { return null }

  if (viewPreference === 'compact') {
    return <li className='bg-white dark:bg-gray-700 sm:p-1 rounded-lg border border-gray-200 dark:border-gray-700'>
      <div className="flex items-start space-x-0 sm:space-x-3 overflow-hidden">
        <div className="relative m-1 hidden sm:block">
          <img className="h-16 w-16 rounded-sm bg-gray-200 flex items-center justify-center ring-4 ring-cccblue" src={project.thumbImageUrl} alt="" />

          <span className="absolute -bottom-0.5 -right-1 bg-cccblue rounded-tl px-0.5 py-px">
            <FilmIcon className="h-4 w-4 text-white" aria-hidden="true" />
          </span>
        </div>
        <div className="flex flex-col justify-start w-full">
          <a href={`/projects/${project.slug}`} className="font-medium text-cccblue cursor-pointer truncate">
            {project.name}
          </a>
          <div className="mt-1 text-sm text-gray-600 dark:text-gray-400 flex flex-col sm:flex-row">
            <span className="uppercase text-xs dark:text-gray-300 text-gray-500 mr-5">
              { project.future && <span className="text-green-700">Future Deadline: {project.deadline}</span> }
              { !project.future && <span className="text-red-700">Deadline Passed: {project.deadline}</span> }
            </span>
            <span className="uppercase text-xs dark:text-gray-300 text-gray-500 mr-5">
              Roles: {project.rolesAvailableCount}/{project.rolesCount}
            </span>
            <span className="uppercase text-xs dark:text-gray-300 text-gray-500 mr-5">
              Followers: {project.followeesCount}
            </span>
            <span className="uppercase text-xs dark:text-gray-300 text-gray-500 mr-5">
              Comments: {project.commentsCount}
            </span>
            { archived && <StatusPill status='default' text='Archived' /> }
          </div>
          <div className="flex space-x-6">
            { archived && <button type="button" onClick={unarchive} className="flex space-x-1 text-gray-400 hover:text-gray-500 dark:text-gray-400 dark:hover:text-gray-300 items-center">
              <ArrowCircleUpIcon className="h-4 w-4 sm:h-5 sm:w-5" aria-hidden="true" />
              <span className="text-xs">Unarchive</span>
            </button> }
            { !archived && <button type="button" onClick={archive} className="flex space-x-1 text-gray-400 hover:text-gray-500 dark:text-gray-400 dark:hover:text-gray-300 items-center">
              <ArchiveIcon className="h-4 w-4 sm:h-5 sm:w-5" aria-hidden="true" />
              <span className="text-xs">Archive</span>
            </button> }
            <button type="button" onClick={remove} className="flex space-x-1 text-gray-400 hover:text-gray-500 dark:text-gray-400 dark:hover:text-gray-300 items-center">
              <TrashIcon className="h-4 w-4 sm:h-5 sm:w-5" aria-hidden="true" />
              <span className="text-xs">Unfavorite</span>
            </button>
            { !editing && <button onClick={() => setEditing(true)} type="button" className="flex space-x-1 items-center text-yellow-800 dark:text-yellow-100">
              <PencilIcon className="h-4 w-4 sm:h-5 sm:w-5" aria-hidden="true" />
              <span className="text-xs cursor-pointer">Edit Private Notes</span>
            </button> }
          </div>

          { (notes || editing) && <div className='mt-2 text-sm bg-yellow-100 rounded-b-md sm:rounded-md dark:bg-yellow-800 text-yellow-800 dark:text-yellow-100 p-2'>{ !editing && <span dangerouslySetInnerHTML={{ __html: notes }} /> }
            { editing && <div className='flex flex-col space-y-1'>
              <FroalaWrapper
                hideButtons={true}
                model={notes}
                focusOnLoad={true}
                placeholderText='Notes go here'
                updateModel={(val) => handleUpdateFroala('notes', val)}
              />
              <button type="button" onClick={handleSubmit(onSubmit)} className="text-xs cursor-pointer">Save Notes</button>
            </div> }
          </div> }
        </div>
      </div>
    </li>
  }

  return <li className='bg-gray-50 dark:bg-gray-900 p-2'>
    <div className="relative mb-4 before:content-[''] before:rounded-md before:absolute before:inset-0 before:bg-black before:bg-opacity-20">
      <div className="aspect-w-16 aspect-h-9">
        <img className="object-cover shadow-lg rounded-lg" src={project.fullImageUrl} alt={project.name} />
      </div>
      <a href={`/projects/${project.slug}`} className="absolute inset-0 p-2 text-white flex flex-col overflow-hidden">
        <div className="relative">
          <div className="bg-gray-900 bg-opacity-80 rounded-md py-1 px-4 text-sm">{project.name}</div>
        </div>
        <div className="mt-auto">
          { project.future && <span className="bg-white bg-opacity-90 py-1 px-4 rounded-md text-green-700">Future Deadline: {project.deadline}</span> }
          { !project.future && <span className="bg-white bg-opacity-90 py-1 px-4 rounded-md text-red-700">Deadline Passed: {project.deadline}</span> }
        </div>
      </a>
    </div>

    <dl className="mt-1 grid grid-cols-3 gap-5 sm:grid-cols-3">
      <div className="bg-white dark:bg-gray-700 shadow rounded-lg overflow-hidden p-2">
        <dt className="text-sm font-medium text-gray-500 truncate">Followers</dt>
        <dd className="mt-1 text-xl font-semibold text-gray-900 dark:text-gray-100">{project.followeesCount}</dd>
      </div>
      <div className="bg-white dark:bg-gray-700 shadow rounded-lg overflow-hidden p-2">
        <dt className="text-sm font-medium text-gray-500 truncate">Roles</dt>
        <dd className="mt-1 text-xl font-semibold text-gray-900 dark:text-gray-100">{project.rolesAvailableCount} / {project.rolesCount}</dd>
      </div>
      <div className="bg-white dark:bg-gray-700 shadow rounded-lg overflow-hidden p-2">
        <dt className="text-sm font-medium text-gray-500 truncate">Comments</dt>
        <dd className="mt-1 text-xl font-semibold text-gray-900 dark:text-gray-100">{project.commentsCount}</dd>
      </div>
    </dl>
    { archived && <StatusPill status='default' text='Archived' /> }
    <div className='mt-2 text-sm bg-yellow-100 dark:bg-yellow-800 text-yellow-800 dark:text-yellow-100 p-2 rounded-sm'>{ !editing && <span dangerouslySetInnerHTML={{ __html: notes }} /> }
      { !editing && <button onClick={() => setEditing(true)} type="button" className="flex space-x-1 items-center">
        <PencilIcon className="h-5 w-5" aria-hidden="true" />
        <span className="text-xs cursor-pointer">Edit Private Notes</span>
      </button> }
      { editing && <div className='flex flex-col space-y-1'>
        <FroalaWrapper
          hideButtons={true}
          model={notes}
          focusOnLoad={true}
          placeholderText='Notes go here'
          updateModel={(val) => handleUpdateFroala('notes', val)}
        />
        <button type="button" onClick={handleSubmit(onSubmit)} className="text-xs cursor-pointer">Save Notes</button>
      </div> }
    </div>
    <div className='flex mt-2 mx-2 justify-between items-center'>
      { archived && <button type="button" onClick={unarchive} className="flex space-x-1 text-gray-400 hover:text-gray-500 dark:text-gray-400 dark:hover:text-gray-300 items-center">
        <ArrowCircleUpIcon className="h-5 w-5" aria-hidden="true" />
        <span className="text-xs">Unarchive</span>
      </button> }
      { !archived && <button type="button" onClick={archive} className="flex space-x-1 text-gray-400 hover:text-gray-500 dark:text-gray-400 dark:hover:text-gray-300 items-center">
        <ArchiveIcon className="h-5 w-5" aria-hidden="true" />
        <span className="text-xs">Archive</span>
      </button> }
      <button type="button" onClick={remove} className="flex space-x-1 text-gray-400 hover:text-gray-500 dark:text-gray-400 dark:hover:text-gray-300 items-center">
        <TrashIcon className="h-5 w-5" aria-hidden="true" />
        <span className="text-xs">Unfavorite</span>
      </button>
    </div>
  </li>
}

const FavoritesIndex = () => {
  const [meta, setMeta] = useState({})
  const [favorites, setFavorites] = useState([])
  const { getRequest, putpostRequest } = useQuery()
  const [loading, setLoading] = useState(true)
  const [viewPreference, setViewPreference] = useState(null)
  const [page, setPage] = useState(1)
  const [order, setOrder] = useState('deadline')
  const [status, setStatus] = useState('active')
  const scrollRef = useRef(null)
  const dropdownRef = useRef(false)
  const { dropdownOpen, setDropdownOpen } = useDropdown(dropdownRef)
  const statuses = ['active', 'archived']

  useDebouncedEffect(() => {
    setDropdownOpen(false)
    fetchData(true)
  }, 200, [status, order])

  useEffect(() => {
    fetchData()
  }, [page])

  useEffect(() => {
    if (viewPreference === null) { return }

    const data = { view_preference_favorite_index: viewPreference }
    putpostRequest('/api/v3/preferences/1', 'PATCH', { preference: data }, (err, jsonData) => {
      if (err) { /* fail silent */ }
    })
  }, [viewPreference])

  const fetchData = (newPage) => {
    setLoading(true)
    const data = {
      status: status,
      order: order,
      page: newPage ? 1 : page
    }
    getRequest('/api/v3/favorites', data, (err, jsonData) => {
      setLoading(false)
      if (err) { /* hooks */ return }

      setFavorites(jsonData.favorites)
      setViewPreference(jsonData.viewPreference)
      setMeta(jsonData.meta)
    })
  }

  const listClasses = viewPreference === 'compact' ? 'space-y-2' : 'sm:grid sm:grid-cols-3 gap-2 space-y-2 sm:space-y-0'
  const changeTab = (tab) => { setStatus(tab) }
  const tablist = [
    { name: 'Favorites', count: null, changeValue: 'active' },
    { name: 'Archive', count: null, changeValue: 'archived' }
  ]

  return <>
    <div className='flex flex-col gap-y-1'>
      <MobileVideomancyAd />
      <MobileEducationAd />
      <MobileAd adID={110} />
    </div>
    <div className='xl:block xl:flex xl:gap-x-2'>
      <div id='favorites' className='max-w-6xl w-full'>
        <Card light title='Favorited Projects'>
          <>
            <div className='pb-1' ref={scrollRef}>
              <div className='flex justify-between items-center'>
                <Tabs statuses={statuses} tablist={tablist} currentTab={status} changeTab={changeTab} />
                <div className='flex space-x-2'>
                  <div className='relative flex items-center'>
                    <button onClick={() => setDropdownOpen(!dropdownOpen)} className='py-1 px-2 text-xs sm:text-sm text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-cccblue focus:ring-2 focus:ring-blue-700 focus:text-cccblue dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white flex space-x-1 items-center'>
                      <span className="sr-only" hidden>Open options</span>
                      <SortAscendingIcon className="h-4 w-4 sm:h-5 sm:w-5" aria-hidden="true" />
                      {order === 'deadline' ? 'Deadline' : 'Favorited Recently'}
                    </button>
                    { dropdownOpen && <div ref={dropdownRef} tabIndex="-1" className={`absolute right-0 z-30 ${dropdownOpen ? '' : 'hidden opacity-0 scale-0'} py-2 overflow-y-auto transition origin-top-right bg-white border border-gray-900 dark:border-gray-100 rounded-md shadow-md dark:bg-gray-800 top-6 max-h-80`}>
                      <div onClick={() => setOrder('deadline')} className='text-gray-700 dark:text-gray-100 flex px-4 py-2 text-sm cursor-pointer hover:text-cccblue-alt dark:hover:text-cccblue-alt' >Deadline</div>
                      <div onClick={() => setOrder('created_at')} className='text-gray-700 dark:text-gray-100 flex px-4 py-2 text-sm cursor-pointer hover:text-cccblue-alt dark:hover:text-cccblue-alt' >Favorited Recently</div>
                    </div> }
                  </div>
                  <div className="inline-flex rounded-md shadow-sm" role="group">
                    <button type="button" onClick={() => setViewPreference('default')} className={`py-1 px-2 text-sm font-medium text-gray-900 bg-white rounded-l-lg border border-gray-200 hover:bg-gray-100 hover:text-cccblue focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-cccblue dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white ${viewPreference === 'default' ? 'text-cccblue' : ''}`}>
                      <ViewGridIcon className='h-4 w-4 sm:h-5 sm:w-5' />
                    </button>
                    <button type="button" onClick={() => setViewPreference('compact')} className={`py-1 px-2 text-sm font-medium text-gray-900 bg-white rounded-r-lg border border-gray-200 hover:bg-gray-100 hover:text-cccblue focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-cccblue dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white ${viewPreference === 'compact' ? 'text-cccblue' : ''}`}>
                      <ViewListIcon className='h-4 w-4 sm:h-5 sm:w-5' />
                    </button>
                  </div>
                </div>
              </div>
              { favorites.length >= 0 && <Pagination meta={meta} callback={setPage} scrollRef={scrollRef} /> }
            </div>
            { loading && <Loading /> }
            <ul role="list" className={listClasses}>
              {favorites.map((fav) => (
                <FavoriteRow key={`pubfav${fav.id}`} favorite={fav} viewPreference={viewPreference} />
              ))}
            </ul>
            { favorites.length >= 0 && <Pagination meta={meta} callback={setPage} scrollRef={scrollRef} /> }
            { favorites.length === 0 && !loading && <NoResults /> }
          </>
        </Card>
      </div>
      <div className='flex flex-col gap-y-1'>
        <SidebarVideomancyAd />
        <SidebarEducationAd />
        <SidebarAd adID={111} />
      </div>
    </div>
  </>
}

export default FavoritesIndex

FavoriteRow.propTypes = {
  favorite: PropTypes.object.isRequired,
  viewPreference: PropTypes.string
}
