import React, { useState, Fragment, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import { TippyTooltip } from '@/shared/ToolTip'
import { useDebouncedEffect } from '@/utils/useDebouncedEffect'

import { SidebarAd, MobileAd, SidebarEducationAd, MobileEducationAd, SidebarVideomancyAd, MobileVideomancyAd } from '@/Layout/Ads'
import StatusPill from '@/shared/StatusPill'
import UserCreditRow from '@/pages/Credits/UserCreditRow'
import PropTypes from 'prop-types'
import UserHoverCard from '@/shared/UserHoverCard'
import useDropdown from '@/hooks/useDropdown'
import Pagination from '@/shared/Pagination'
import Tabs from '@/shared/Tabs'
import useQuery from '@/hooks/useQuery'
import Loading from '@/shared/Loading'
import { useGlobalState } from '@/state'
import { CheckIcon, ChevronDownIcon, BanIcon, StarIcon, MailIcon, InformationCircleIcon, FilmIcon, ViewGridIcon, ViewListIcon, SortAscendingIcon } from '@heroicons/react/solid'

import Card from '@/shared/Card'
import NoResults from '@/pages/Credits/NoResults'

const UserCreditRequestRow = ({ userCredit }) => {
  let pillColor = 'bg-red-500'
  const [selected, setSelected] = useState(userCredit.status)
  const [deleted, setDeleted] = useState('notdeleted')
  const [, setToast] = useGlobalState('toast')
  const { putpostRequest } = useQuery()
  const dropdownRef = useRef(false)
  const { dropdownOpen, setDropdownOpen } = useDropdown(dropdownRef)

  const performDelete = () => {
    putpostRequest(`/api/v3/user_credits/${userCredit.id}`, 'DELETE', { }, (err, jsonData) => {
      if (err) { /* hook */ return }

      setToast(<div className="ml-3 w-0 flex-1 pt-0.5">
        <p className="text-sm font-medium dark:text-white text-gray-800">Deleted</p>
        <p className="mt-1 text-sm dark:text-gray-100 text-gray-500">So Long, King(?) Bowser</p>
      </div>)
      setDeleted('deleted')
    })
  }

  const changeStatus = (perm) => {
    setSelected(perm)
    setDropdownOpen(false)
    const data = { status: perm }
    putpostRequest(`/api/v3/user_credits/${userCredit.id}/change_status`, 'POST', { user_credit: data }, (err, jsonData) => {
      if (err) { /* hook */ }
    })
  }

  if (deleted === 'deleted') { return null }

  if (userCredit.stage === 'announced') {
    pillColor = 'bg-green-500'
  } else if (userCredit.stage === 'PreProduction') {
    pillColor = 'bg-yellow-500'
  } else if (userCredit.stage === 'Production') {
    pillColor = 'bg-cccblue'
  } else if (userCredit.stage === 'PostProduction') {
    pillColor = 'bg-cccorange'
  }

  return <li className='bg-gray-100 dark:bg-gray-700 px-2 py-2 sm:px-3 rounded-lg shadow-sm'>
    <div className="flex items-start space-x-1 sm:space-x-3">
      <div className="relative m-1">
        <img className="h-8 w-8 sm:h-10 sm:w-10 rounded-sm bg-gray-200 flex items-center justify-center ring-4 ring-cccblue" src={userCredit.userImageUrl} alt={userCredit.userDisplayName} />

        <span className="absolute -bottom-0.5 -right-1 bg-cccblue rounded-tl px-0.5 py-px">
          <FilmIcon className="h-4 w-4 text-white" aria-hidden="true" />
        </span>
      </div>
      <div className="flex-1 py-0 w-full truncate">
        <div className='flex flex-col truncate'>
          <div className='flex items-start justify-between space-x-1'>
            <div className='flex flex-col truncate'>
              <div className='flex items-center justify-start space-x-3'>
                <span className="uppercase text-xs dark:text-gray-300 text-gray-500"> {userCredit.year} </span>
                { userCredit.stage && <span className={`flex-shrink-0 inline-block px-2 py-0.5 text-white ${pillColor} text-xs font-medium rounded-full flex cursor-pointer capitalize`}> {userCredit.stage} </span> }
              </div>
              { userCredit.parentName && <span className='text-sm dark:text-gray-300 text-gray-500'>Part of series: <Link to={`/credits/${userCredit.parentSlug}`} className="text-cccblue">{userCredit.parentName}</Link></span> }
              <span><UserHoverCard username={userCredit.username} displayName={userCredit.userDisplayName} /></span>
              <Link to={`/credits/${userCredit.slug}`} className="font-medium text-cccblue truncate">{userCredit.name}</Link>
              <span className='dark:text-gray-300 text-gray-500'> {userCredit.creditType} {userCredit.role && <span> - {userCredit.role }</span> } </span>
            </div>
          </div>
        </div>
      </div>
      <div className='flex flex-col space-y-2'>
        <div className='relative flex items-center'>
          <div className="inline-flex shadow-sm rounded-md divide-x divide-white">
            <div onClick={() => setDropdownOpen(!dropdownOpen)} className="relative z-0 inline-flex shadow-sm rounded-md divide-x divide-white cursor-pointer">
              <div className="relative inline-flex items-center bg-cccblue p-2 border border-transparent rounded-l-md shadow-sm text-white w-48">
                { selected === 'confirmed' && <StarIcon className="h-4 w-4 sm:h-5 sm:w-5" aria-hidden="true" /> }
                { selected === 'requested' && <MailIcon className="h-4 w-4 sm:h-5 sm:w-5" aria-hidden="true" /> }
                { selected === 'rejected' && <BanIcon className="h-4 w-4 sm:h-5 sm:w-5" aria-hidden="true" /> }
                <p className="ml-2.5 text-sm font-medium capitalize">{selected}</p>
              </div>
              <div className="relative inline-flex items-center bg-cccblue p-2 rounded-l-none rounded-r-md text-sm font-medium text-white">
                <span className="sr-only">Change published status</span>
                <ChevronDownIcon className="h-4 w-4 sm:h-5 sm:w-5" aria-hidden="true" />
              </div>
            </div>
          </div>
          { dropdownOpen && <div ref={dropdownRef} tabIndex="-1" className={`origin-top-left sm:origin-top-right absolute z-10 sm:right-0 mt-2 w-72 rounded-md shadow-lg bg-white dark:bg-gray-800 divide-y divide-gray-200 ring-1 ring-black ring-opacity-5 focus:outline-none  ${dropdownOpen ? '' : 'hidden opacity-0 scale-0'} py-2 overflow-y-auto transition border border-gray-900 dark:border-gray-100 rounded-md top-6 max-h-80`}>
            <div className="flex flex-col  cursor-default select-none relative p-2 text-sm hover:text-cccblue text-gray-900 dark:text-gray-100" onClick={() => changeStatus('requested')}>
              <div className="flex justify-between">
                <p className='font-normal capitalize'>Requested</p>
                { selected === 'requested' && <CheckIcon className="h-5 w-5 text-cccblue" aria-hidden="true" /> }
              </div>
              <span className='text-xs sm:text-sm text-gray-500 dark:text-gray-200 dark:bg-gray-800'>This person has requested to be credited</span>
            </div>
            <div className="flex flex-col cursor-default select-none relative p-2 text-sm hover:text-cccblue text-gray-900 dark:text-gray-100" onClick={() => changeStatus('rejected')}>
              <div className="flex justify-between">
                <p className='font-normal capitalize'>Reject</p>
                { selected === 'rejected' && <CheckIcon className="h-5 w-5 text-cccblue" aria-hidden="true" /> }
              </div>
              <span className='text-xs sm:text-sm text-gray-500 dark:text-gray-200 dark:bg-gray-800'>Deny this request</span>
            </div>
            <div className="flex flex-col cursor-default select-none relative p-2 text-sm hover:text-cccblue text-gray-900 dark:text-gray-100" onClick={() => changeStatus('confirmed')}>
              <div className="flex justify-between">
                <p className='font-normal capitalize'>Confirm</p>
                { selected === 'confirmed' && <CheckIcon className="h-5 w-5 text-cccblue" aria-hidden="true" /> }
              </div>
              <span className='text-xs sm:text-sm text-gray-500 dark:text-gray-200 dark:bg-gray-800'>Confirm this request</span>
            </div>
          </div> }
        </div>
        { selected === 'rejected' && deleted === 'notdeleted' && <button type="button" onClick={() => setDeleted('confirmdeleted')} className="bg-gray-100 dark:bg-gray-700 rounded-md text-sm text-cccblue hover:text-cccblue-alt"> Delete </button> }
        { deleted === 'confirmdeleted' && <button type="button" onClick={performDelete} className="bg-gray-100 dark:bg-gray-700 rounded-md text-sm text-red-500"> Really delete this? </button> }
      </div>
    </div>
  </li>
}

const UserCreditRequestList = () => {
  const [userCredits, setUserCredits] = useState([])
  const { getRequest } = useQuery()

  useEffect(() => { fetchData() }, [])

  const fetchData = () => {
    getRequest('/api/v3/credits/confirmation_needed', {}, (err, jsonData) => {
      if (err) { /* hooks */ return }
      setUserCredits(jsonData.userCredits)
    })
  }

  if (userCredits.length === 0) { return null }

  return <>
    <div className="md:col-span-1" />
    <Card title='Credit Requests'>
      <div className="grid grid-cols-3 gap-6">
        <div className='col-span-3'>
          <div className="sm:rounded-md">
            <ul className="space-y-3">
              { userCredits.map((uc, idx) => (
                <UserCreditRequestRow key={`uscredit${uc.id}`} userCredit={uc} />
              ))}
            </ul>
          </div>
        </div>
      </div>
    </Card>
  </>
}

const CreditRow = ({ credit, viewPreference }) => {
  const { putpostRequest } = useQuery()
  const [deleted, setDeleted] = useState(false)
  let pillColor = 'success'

  const performDelete = () => {
    putpostRequest(`/api/v3/credits/${credit.id}`, 'DELETE', { }, (err, jsonData) => {
      if (err) { /* hook */ return }

      setToast(<div className="ml-3 w-0 flex-1 pt-0.5">
        <p className="text-sm font-medium dark:text-white text-gray-800">Deleted</p>
        <p className="mt-1 text-sm dark:text-gray-100 text-gray-500">So Long, King(?) Bowser</p>
      </div>)
      setDeleted('deleted')
    })
  }

  if (deleted) { return null }

  if (credit.stage === 'announced') {
    pillColor = 'new'
  } else if (credit.stage === 'PreProduction') {
    pillColor = 'default'
  } else if (credit.stage === 'Production') {
    pillColor = 'warning'
  } else if (credit.stage === 'PostProduction') {
    pillColor = 'error'
  }

  if (viewPreference === 'compact') {
    return <li className='bg-white dark:bg-gray-700 sm:p-1 rounded-lg border border-gray-200 dark:border-gray-700'>
      <div className="flex items-start space-x-0 sm:space-x-3 overflow-hidden">
        <div className="relative m-1 hidden sm:block">
          <img className="h-16 w-16 rounded-sm bg-gray-200 flex items-center justify-center ring-4 ring-cccblue" src={credit.imageUrl} alt="" />

          <span className="absolute -bottom-0.5 -right-1 bg-cccblue rounded-tl px-0.5 py-px">
            <FilmIcon className="h-4 w-4 text-white" aria-hidden="true" />
          </span>
        </div>
        <div className="flex flex-col justify-start w-full">
          { credit.parentName && <span className='text-sm dark:text-gray-300 text-gray-500'>Part of series: <Link to={`/credits/${credit.parentSlug}`} className="text-cccblue">{credit.parentName}</Link></span> }
          <Link to={`/credits/${credit.slug}`} className="font-medium text-cccblue truncate">{credit.name}</Link>
          <div className="flex justify-start items-center">
            <StatusPill status={pillColor} text={credit.stage} />
          </div>
        </div>
      </div>
    </li>
  }

  return <li className='bg-gray-50 dark:bg-gray-900 p-2'>
    <Link to={`/credits/${credit.slug}`} className="font-medium text-cccblue truncate">
      <div className="relative mb-4 before:content-[''] before:rounded-md before:absolute before:inset-0 before:bg-black before:bg-opacity-20">
        <div className="aspect-w-16 aspect-h-9">
          <img className="object-cover shadow-lg rounded-lg" src={credit.imageUrl} alt={credit.name} />
        </div>
        <div className="relative">
          { credit.parentName && <span className='text-sm dark:text-gray-300 text-gray-500'>Part of series: {credit.parentName}</span> }
          <div className='truncate'>{credit.name}</div>
        </div>
        <div className="flex justify-start items-center">
          <StatusPill status={pillColor} text={credit.stage} />
        </div>
      </div>
    </Link>
  </li>
}


const MyCreditsIndex = () => {
  const { getRequest } = useQuery()
  const [userCredits, setUserCredits] = useState([])

  useEffect(() => {
    getRequest(`/api/v3/user_credits/`, {}, (err, jsonData) => {
      if (err) { /* handled in hook */ return }
      setUserCredits(jsonData.userCredits)
    })
  }, [])

  return <div>
    <Card title="My Credits">
      <div className="min-w-full divide-y divide-gray-200">
        <div className='hidden sm:block'>
          <div className="bg-gray-50 dark:bg-gray-700 text-gray-500 dark:text-gray-300 p-0 sm:p-2 flex justify-start items-center space-x-1 sm:space-x-3">
            <div className="w-64 text-xs font-medium uppercase tracking-wider"> Name </div>
            <div className="w-64 text-xs font-medium uppercase tracking-wider"> Credited For </div>
            <div className="relative px-6 py-3"> <span className="sr-only" hidden>Edit</span> </div>
          </div>
        </div>
        {userCredits.map((userCredit, idx) => (
          <UserCreditRow
            key={`ucform${userCredit.id}`}
            idx={idx}
            initialUserCredit={userCredit}
            creditId={userCredit.creditId}
            canEdit={false}
          />
        ))}
      </div>
    </Card>
  </div>
}

const CreditIndex = () => {
  const [credits, setCredits] = useState([])
  const [userCreditInvites, setUserCreditInvites] = useState([])
  const { getRequest, putpostRequest } = useQuery()
  const scrollRef = useRef(null)
  const [loading, setLoading] = useState(true)
  const [meta, setMeta] = useState({})
  const [stage, setStage] = useState('all')
  const [stageCounts, setStageCounts] = useState(null)
  const [viewPreference, setViewPreference] = useState(null)
  const [page, setPage] = useState(1)
  const [order, setOrder] = useState('updated_at')
  const dropdownRef = useRef(false)
  const { dropdownOpen, setDropdownOpen } = useDropdown(dropdownRef)
  const stages = ['All', 'Announced', 'Completed', 'Production']

  useDebouncedEffect(() => {
    setDropdownOpen(false)
    fetchData(true)
  }, 200, [stage, order])
  useEffect(() => { fetchData() }, [page])
  useEffect(() => { fetchInvites() }, [])

  useEffect(() => {
    if (viewPreference === null) { return }

    const data = { view_preference_credit_index: viewPreference }
    putpostRequest('/api/v3/preferences/1', 'PATCH', { preference: data }, (err, jsonData) => {
      if (err) { /* fail silent */ }
    })
  }, [viewPreference])

  const fetchInvites = () => {
    getRequest('/api/v3/user_credits/invites', {}, (err, jsonData) => {
      if (err) { /* hooks */ return }
      setUserCreditInvites(jsonData.userCreditInvites)
    })
  }

  const fetchData = (newPage) => {
    setLoading(true)
    const data = {
      stage: stage,
      order: order,
      page: newPage ? 1 : page
    }
    getRequest('/api/v3/credits', data, (err, jsonData) => {
      setLoading(false)
      if (err) { /* hooks */ return }
      if (!stageCounts) { setStageCounts(jsonData.stages) }
      setCredits(jsonData.credits)
      setViewPreference(jsonData.viewPreference)
      setMeta(jsonData.meta)
    })
  }

  const listClasses = viewPreference === 'compact' ? 'space-y-2' : 'sm:grid sm:grid-cols-3 gap-2 space-y-2 sm:space-y-0'
  const changeTab = (tab) => { setStage(tab) }
  const tablist = [
    { name: 'All', count: null, changeValue: 'all' },
    { name: 'Announced', count: stageCounts?.Announced, changeValue: 'Announced' },
    { name: 'Completed', count: stageCounts?.Completed, changeValue: 'Completed' },
    { name: 'Production', count: stageCounts?.Production, changeValue: 'Production' }
  ]

  return <>
    <div className='flex flex-col gap-y-1'>
      <MobileVideomancyAd />
      <MobileEducationAd />
      <MobileAd adID={109} />
    </div>
    { userCreditInvites.length > 0 && <>
      <Card title="Productions You Are Invited To">
        <div className="min-w-full divide-y divide-gray-200">
          <div className='hidden sm:block'>
            <div className="bg-gray-50 dark:bg-gray-700 text-gray-500 dark:text-gray-300 p-0 sm:p-2 flex justify-start items-center space-x-1 sm:space-x-3">
              <div className="w-64 text-xs font-medium uppercase tracking-wider"> Name </div>
              <div className="w-64 text-xs font-medium uppercase tracking-wider"> Credited For </div>
              <div className="w-64 text-xs font-medium uppercase tracking-wider"> Production Name </div>
            </div>
          </div>
          {userCreditInvites.map((userCredit, idx) => (
            <UserCreditRow
              key={`ucform${userCredit.id}`}
              initialUserCredit={userCredit}
              creditId={userCredit.creditId}
              idx={idx}
              canEdit={false}
            />
          ))}
        </div>
      </Card>
      <div className='py-4'></div>
    </>}

    <div className='lg:block lg:flex lg:gap-x-2'>
      <div id='credit_index' className='max-w-6xl w-full'>
        <Card title={
          <div className='flex flex-col sm:flex-row items-center justify-between'>
            <span>
              My Productions
              <TippyTooltip content={
                <div className="rounded-md bg-yellow-50 p-2 sm:p-4 m-2">
                  <div className="mt-1 text-xs sm:text-sm text-gray-600 dark:text-gray-700 flex flex-col space-y-2">
                    <div>Here, you can list all the stuff you've been credited for, like IMDb. This can be a podcast you were in, a youtube video you voiced for, a cartoon you animated, or anything you were listed as credited. Everything you list here will be <b>searchable</b> on Google and CCC search.</div>
                    <div> NOTE: you can create a Credit even if you are not the Production Owner. You can transfer the ownership at any time. </div>
                  </div>
                </div>
                }>
                <span className='text-xs text-cccblue flex items-center'>What is this? <InformationCircleIcon className='h-4 w-4' /></span>
              </TippyTooltip>
            </span>
            <Link to='/credits/new'>Add New Production</Link>
            </div>
          }>
          <>
            <div className='pb-1' ref={scrollRef}>
              <div className='flex justify-between items-center'>
                <Tabs statuses={stages} tablist={tablist} currentTab={stage} changeTab={changeTab} />
                <div className='flex space-x-2'>
                  <div className='relative flex items-center'>
                    <button onClick={() => setDropdownOpen(!dropdownOpen)} className='py-1 px-2 text-xs sm:text-sm text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-cccblue focus:ring-2 focus:ring-blue-700 focus:text-cccblue dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white flex space-x-1 items-center'>
                      <span className="sr-only" hidden>Open options</span>
                      <SortAscendingIcon className="h-4 w-4 sm:h-5 sm:w-5" aria-hidden="true" />
                      {order === 'year' ? 'Year' : 'Recently Updated'}
                    </button>
                    { dropdownOpen && <div ref={dropdownRef} tabIndex="-1" className={`absolute right-0 z-30 ${dropdownOpen ? '' : 'hidden opacity-0 scale-0'} py-2 overflow-y-auto transition origin-top-right bg-white border border-gray-900 dark:border-gray-100 rounded-md shadow-md dark:bg-gray-800 top-6 max-h-80`}>
                      <div onClick={() => setOrder('year')} className='text-gray-700 dark:text-gray-100 flex px-4 py-2 text-sm cursor-pointer hover:text-cccblue-alt dark:hover:text-cccblue-alt' >Year</div>
                      <div onClick={() => setOrder('updated_at')} className='text-gray-700 dark:text-gray-100 flex px-4 py-2 text-sm cursor-pointer hover:text-cccblue-alt dark:hover:text-cccblue-alt' >Recent Activity</div>
                    </div> }
                  </div>
                  <div className="inline-flex rounded-md shadow-sm" role="group">
                    <button type="button" onClick={() => setViewPreference('default')} className={`py-1 px-2 text-sm font-medium text-gray-900 bg-white rounded-l-lg border border-gray-200 hover:bg-gray-100 hover:text-cccblue focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-cccblue dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white ${viewPreference === 'default' ? 'text-cccblue' : ''}`}>
                      <ViewGridIcon className='h-4 w-4 sm:h-5 sm:w-5' />
                    </button>
                    <button type="button" onClick={() => setViewPreference('compact')} className={`py-1 px-2 text-sm font-medium text-gray-900 bg-white rounded-r-lg border border-gray-200 hover:bg-gray-100 hover:text-cccblue focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-cccblue dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white ${viewPreference === 'compact' ? 'text-cccblue' : ''}`}>
                      <ViewListIcon className='h-4 w-4 sm:h-5 sm:w-5' />
                    </button>
                  </div>
                </div>
              </div>
              { credits.length > 0 && <Pagination meta={meta} callback={setPage} scrollRef={scrollRef} /> }
            </div>
            { loading && <Loading /> }
            <ul role="list" className={listClasses}>
              {credits.map((credit) => (
                <CreditRow key={`credi${credit.id}`} credit={credit} viewPreference={viewPreference} />
              ))}
            </ul>
            { credits.length > 0 && <Pagination meta={meta} callback={setPage} scrollRef={scrollRef} /> }
            { credits.length === 0 && !loading && <NoResults /> }
          </>
        </Card>
        <div className='py-4'></div>
        <MyCreditsIndex />
      </div>
      <div className='flex flex-col gap-y-1'>
        <SidebarVideomancyAd />
        <SidebarEducationAd />
        <SidebarAd adID={108} />
      </div>
    </div>
  </>
}

export default CreditIndex

CreditRow.propTypes = {
  credit: PropTypes.object.isRequired
}
UserCreditRequestRow.propTypes = {
  userCredit: PropTypes.object.isRequired
}
